<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card
        elevation="0"
        v-if="
          permissions && permissions.proposal && permissions.proposal.canView
        "
      >
        <v-card-title class="orange-bg font-weight-bold white--text">
          <span>
            Proposals
          </span>
          <!-- <v-spacer></v-spacer>
          <v-btn small class="orange--text">
            Upload New Proposal
          </v-btn> -->
        </v-card-title>
        <v-card-text class="mt-3">
          <!-- Start Proposals section -->
          <v-data-table
            :headers="headers"
            :items="records"
            item-key="id"
            :loading="isLoading"
            :server-items-length="totalRecords"
            :options.sync="options"
            loading-text="Loading... Please wait"
            class="elevation-1"
          >
            <template #[`item.title`]="{ item }">
              <a target="_blank" :href="item.fileUrl">{{ item.title }}</a>
            </template>
            <template #[`item.proposalDate`]="{ item }">
              {{ formatDateUs(item.proposalDate) }}
            </template>
            <template #[`item.actions`]="{ item }">
              <span v-if="item.isApproved" class="green--text">Approved</span>
              <span v-if="item.isDeclined" class="green--text">Declined</span>
              <span v-if="item.isOnHold" class="green--text">On-Hold</span>
              <v-btn
                small
                v-if="
                  permissions &&
                    permissions.proposal &&
                    permissions.proposal.canEdit &&
                    !item.isApproved &&
                    !item.isDeclined &&
                    !item.isOnHold
                "
                class="blue white--text mr-1"
                @click.stop="item.approveDialog = true"
              >
                Approve
              </v-btn>
              <v-btn
                small
                v-if="
                  permissions &&
                    permissions.proposal &&
                    permissions.proposal.canEdit &&
                    !item.isApproved &&
                    !item.isDeclined &&
                    !item.isOnHold
                "
                class="blue white--text mr-1"
                @click.stop="item.declineDialog = true"
              >
                Decline
              </v-btn>
              <v-btn
                small
                v-if="
                  permissions &&
                    permissions.proposal &&
                    permissions.proposal.canEdit &&
                    !item.isApproved &&
                    !item.isDeclined &&
                    !item.isOnHold
                "
                class="blue white--text mr-1"
                @click.stop="item.onHoldDialog = true"
              >
                On-Hold
              </v-btn>
              <router-link
                :to="
                  `/customerView/${customerId}/messages/new?recipient=${customerId}`
                "
              >
                <v-btn
                  small
                  v-if="!item.isApproved && !item.isDeclined && !item.isOnHold"
                  class="blue white--text"
                >
                  ?
                </v-btn>
              </router-link>

              <v-dialog v-model="item.approveDialog" persistent max-width="290">
                <v-card>
                  <v-card-title></v-card-title>
                  <v-card-text
                    >Are you sure you want to approve proposal {{ item.title }}?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="item.approveProcessing"
                      color="green darken-1"
                      text
                      @click="item.approveDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :loading="item.approveProcessing"
                      color="green darken-1"
                      text
                      @click="toggleApproveStatus(item)"
                    >
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="item.declineDialog" persistent max-width="290">
                <v-card>
                  <v-card-title></v-card-title>
                  <v-card-text
                    >Are you sure you want to decline proposal {{ item.title }}?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="item.declineProcessing"
                      color="green darken-1"
                      text
                      @click="item.declineDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :loading="item.declineProcessing"
                      color="green darken-1"
                      text
                      @click="declineProposal(item)"
                    >
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="item.onHoldDialog" persistent max-width="290">
                <v-card>
                  <v-card-title></v-card-title>
                  <v-card-text
                    >Are you sure you want to put proposal {{ item.title }} on
                    hold?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="item.onHoldProcessing"
                      color="green darken-1"
                      text
                      @click="item.onHoldDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :loading="item.onHoldProcessing"
                      color="green darken-1"
                      text
                      @click="putProposalOnHold(item)"
                    >
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
          <!-- End Proposals section -->
        </v-card-text>
      </v-card>
      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import {
  GET_QUERY,
  POST_DATA,
  API_CUSTOMERS,
  API_SERVICE_REQUESTS,
  API_SERVICE_REQUEST_PRIORITIES,
  API_SERVICE_REQUEST_TYPES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    headers: [
      { text: "Date", value: "proposalDate", class: "orange-bg" },
      { text: "Name", value: "title", class: "orange-bg" },
      { text: "Amount", value: "amount", class: "orange-bg" },
      { text: "Actions", value: "actions", sortable: false, class: "orange-bg" }
    ],
    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["proposalDate"],
      sortDesc: [true]
    },

    newRequest: {
      communityName: "",
      title: "",
      requestType: "",
      description: "",
      isInternal: false,
      attachments: [],
      priority: "",
      assignedTo: "",
      targetCompletitioDate: null
    },

    requestTypeItems: [],
    priorityItems: [],

    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    records: [],
    totalRecords: 0,
    customDateMenu: false,
    customDates: [],
    formattedCustomDates: "",
    permissions: {},
    serviceRequestProcessing: false,
    loadingPriorityItems: false,
    loadingServiceRequestTypes: false
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    options: async function() {
      // this.isLoading = true;
      // if (this.previousPage < this.options.page && this.options.page !== 1) {
      //   this.setStartingAfter();
      // } else if (
      //   this.previousPage > this.options.page &&
      //   this.options.page !== 1
      // ) {
      //   this.setEndingBefore();
      // } else {
      //   this.startingAfter = "";
      //   this.endingBefore = "";
      // }
      // this.isLoading = false;
      await this.getRecords();
    }
    // "options.sortBy": async function() {
    //   await this.getRecords();
    // }
  },
  async mounted() {
    await this.getRecords();
  },
  async created() {
    await this.getComunityInfo();
    await permissionsHelper.getPermissions().then(this.getPermissions);

    await this.getServiceRequestPriorities();
    await this.getServiceRequestTypes();
  },
  methods: {
    async getServiceRequestPriorities() {
      this.loadingPriorityItems = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_SERVICE_REQUEST_PRIORITIES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.priorityItems = response.data.$values;
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          this.loadingPriorityItems = false;
        });
    },
    async getServiceRequestTypes() {
      this.loadingServiceRequestTypes = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_SERVICE_REQUEST_TYPES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.requestTypeItems = response.data.$values;
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          this.loadingServiceRequestTypes = false;
        });
    },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerView",
        params: { customerId: communityId }
      });
    },
    async getRecords() {
      this.isLoading = true;

      let self = this;
      let url = `${API_CUSTOMERS}/${this.customerId}/proposals`;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: url
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            let items = response.data;
            self.totalRecords = items.length;

            items.forEach(el => {
              el.approveDialog = false;
              el.approveProcessing = false;

              el.declineDialog = false;
              el.declineProcessing = false;

              el.onHoldDialog = false;
              el.onHoldProcessing = false;
            });

            if (sortBy.length === 1 && sortDesc.length === 1) {
              items = items.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              });
            }

            if (itemsPerPage > 0) {
              items = items.slice(
                (page - 1) * itemsPerPage,
                page * itemsPerPage
              );
            }

            self.records = items;
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive a list of proposals. Something went wrong!",
              color: "red"
            });
          }

          self.isLoading = false;
        });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);

            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive community info. Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async toggleApproveStatus(item) {
      item.approveProcessing = true;
      let url = `${API_CUSTOMERS}/${this.customerId}/proposals/${item.id}/approve`;

      await this.$store
        .dispatch(POST_DATA, {
          listName: url
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            item.isApproved = true;

            item.approveDialog = false;

            this.createServiceRequest(item);
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot approve proposal. Something went wrong!",
              color: "red"
            });
          }

          item.approveProcessing = false;
        });
    },
    async declineProposal(item) {
      item.declineProcessing = true;
      let url = `${API_CUSTOMERS}/${this.customerId}/proposals/${item.id}/decline`;

      await this.$store
        .dispatch(POST_DATA, {
          listName: url
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            item.isDeclined = true;
            item.declineDialog = false;
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot approve proposal. Something went wrong!",
              color: "red"
            });
          }

          item.declineProcessing = false;
        });
    },

    async putProposalOnHold(item) {
      item.onHoldProcessing = true;
      let url = `${API_CUSTOMERS}/${this.customerId}/proposals/${item.id}/onHold`;

      await this.$store
        .dispatch(POST_DATA, {
          listName: url
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            item.isOnHold = true;
            item.onHoldDialog = false;
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot approve proposal. Something went wrong!",
              color: "red"
            });
          }

          item.onHoldProcessing = false;
        });
    },

    createServiceRequest(item) {
      this.newRequest.communityId = this.communityInfo.aspireId;
      this.newRequest.isOpen = true;
      this.newRequest.title =
        'Proposal "' + item.title + '" has just been approved.';

      let requestTypeObj = this.requestTypeItems[0];
      if (this.requestTypeItems.filter(el => el.name === "Other").length > 0)
        requestTypeObj = this.requestTypeItems.filter(
          el => el.name === "Other"
        )[0];

      this.newRequest.requestType = {
        id: requestTypeObj.id,
        name: requestTypeObj.name
      };

      let priorityObj = this.priorityItems[0];
      if (this.priorityItems.filter(el => el.name === "Normal").length > 0)
        priorityObj = this.priorityItems.filter(el => el.name === "Normal")[0];

      this.newRequest.priority = {
        id: priorityObj.id,
        name: priorityObj.name
      };

      this.newRequest.assignedTo = {
        id: this.communityInfo.accountManager.id
      };
      this.newRequest.targetCompletionDate = moment.utc(item.proposalDate);

      this.newRequest.attachments = [{ id: item.id, fileUrl: item.fileUrl }];

      this.serviceRequestProcessing = true;
      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_SERVICE_REQUESTS}`,
          data: this.newRequest
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent =
              "Service Request has been created successfully";

            self.$router.push({
              name: "CustomerViewRequests",
              params: { customerId: self.customerId }
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
          self.serviceRequestProcessing = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Proposals" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Proposals" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    }
  },
  computed: {}
};
</script>
